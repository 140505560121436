// Sorted ASC by size. That's important.
// It can't be configured as it's used statically for propTypes.
export const breakpointKeys = ['xs', 'sm', 'md', 'lg', 'xl', 'xxl'];

// Keep in mind that @media is inclusive by the CSS specification.
export default function createBreakpoints(breakpoints) {
	const {
		// The breakpoint **start** at this value.
		// For instance with the first breakpoint xs: [xs, sm).
		values = {
			xs: 0, // phone
			sm: 600, // tablets
			md: 900, // small laptop
			lg: 1200, // desktop
			xl: 1536, // large screens
			xxl: 1800, // extra large screen
		},
		unit = 'px',
		step = 5,
		...other
	} = breakpoints;

	const keys = Object.keys(values);

	function up(key) {
		const value = typeof values[key] === 'number' ? values[key] : key;
		return `@media (min-width:${value}${unit})`;
	}

	function down(key) {
		const value = typeof values[key] === 'number' ? values[key] : key;
		return `@media (max-width:${value - step / 100}${unit})`;
	}

	function between(start, end) {
		const endIndex = keys.indexOf(end);

		return (
			`@media (min-width:${typeof values[start] === 'number' ? values[start] : start}${unit}) and ` +
			`(max-width:${(endIndex !== -1 && typeof values[keys[endIndex]] === 'number' ? values[keys[endIndex]] : end) - step / 100}${unit})`
		);
	}

	function only(key) {
		if (keys.indexOf(key) + 1 < keys.length) {
			return between(key, keys[keys.indexOf(key) + 1]);
		}

		return up(key);
	}

	return {
		keys,
		values,
		up,
		down,
		between,
		only,
		unit,
		...other,
	};
}
